.card-component__item {
  background-color: #f4f4f4;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.card-component__item:hover {
  background-color: #07a780;
  color: white;
  transform: scale(1.05);
}

.card-component__item__present {
  background: #07a780;
  color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.card-component__item__present:hover {
  color: #fff;
  background: #454545;
  transform: scale(1.05);
}

.card-component__item__top {
  font-family: "Nunito";
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-component__item__description {
  font-family: "Nunito";
  font-size: 1.1rem;
  font-weight: 600;
  white-space: pre-wrap;
}
.card-component__item__link {
  font-family: "Nunito";
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: bold;
  white-space: pre-wrap;
}
.card-component__item__date {
  font-family: "Nunito";
  font-size: 1rem;
  font-weight: 700;
}
