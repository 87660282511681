.title-seperator-component__title {
    font-family: "Nunito";
    font-size: 2.2rem;
    font-weight: 700;
    color: #07a780;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .title-seperator-component__line {
    margin-top: 25px;
    margin-left: 5%;
    margin-right: 5%;
    width: 70%;
    height: 2px;
    background: #07a780;
    border: 2px solid #07a780;
    border-radius: 5px;
  }