.resume-info__root {
  cursor: pointer;
  margin-top: 70px;
  margin-left: 1%;
  color: white;
  background: #07a780;
  border-radius: 25px;
  width: 40%;
  height: max-content;
  padding-top: 50px;
  padding-bottom: 50px;

  transition: all 0.2s ease-in-out;
}

.resume-info__root:hover {
  /* color: #07a780; */
  background: #454545;
}

.resume-info__attribute__hobbies {
  margin-left: 20px;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.resume-info__title {
  display: block;
  font-size: 2.3rem;
  font-weight: 700;
  margin-left: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: "Nunito";
}

.resume-info__attribute {
  margin: 20px;
  transition: all 0.3s ease-in-out;
}

.resume-info__attribute:hover {
  transform: scale(1.08);
}

.resume-info__attribute__hobbies:hover {
  transform: scale(1.08);
}

.resume-info__attribute__name {
  font-family: "Nunito";
  font-size: 1.5rem;
  font-weight: 800;
}
.resume-info__attribute__value {
  font-family: "Nunito";
  font-size: 1.2rem;
  font-weight: 600;
  white-space: pre-wrap;
}

@media (max-width: 1200px) and (min-width: 601px) {
  .resume-info__root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;
    border-radius: 25px;
    width: 40%;
    margin-left: 0;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .resume-info__title {
    display: block;
    font-size: 2.3rem;
    font-weight: 700;
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    font-family: "Nunito";
  }
}

@media (max-width: 600px) {
  .resume-info__root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;
    border-radius: 25px;
    width: 80%;
    margin-left: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .resume-info__title {
    display: block;
    font-size: 2.3rem;
    font-weight: 700;
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    font-family: "Nunito";
  }
}
