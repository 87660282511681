.cover-letter-component__root {
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #454545;
  gap: 0;
  padding: 20px;
  border-radius: 20px;
  width: 90%;
  transition: all 0.2s ease-in-out;
}

.cover-letter-component__root:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.cover-letter-component__name {
  color: #07a780;
  font-family: "Nunito";
  font-size: 1.8rem;
  font-weight: 700;
}

.cover-letter-component__title {
  color: #07a780;
  font-family: "Nunito";
  font-size: 1.2rem;
  font-weight: 700;
}

.cover-letter-component__content {
  color: #ffffff;
  font-family: "Nunito";
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 20px;
}

@media (max-width: 850px) {
  .cover-letter-component__root {
    display: flex;
    flex-direction: column;
    justify-content: start;
    background: #454545;
    gap: 0;
    padding: 20px;
    border-radius: 20px;
    width: 90%;
  }
}
