.resume-top__root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resume-top__image__wrapper {
  transition: all 0.2s ease-in-out;
  width: 35%;
  margin-left: 1%;
}

.resume-top__image {
  object-fit: cover;
  width: 60%;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.resume-top__image:hover {
  border-radius: 20%;
}

@media (max-width: 1300px) {
  .resume-top__root {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .resume-top__image {
    object-fit: cover;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    margin-bottom: 5vh;
  }

  .resume-top__image:hover {
    border-radius: 20%;
  }

  .resume-top__image__wrapper {
    transition: all 0.2s ease-in-out;
    text-align: center;
    width: 60%;
  }
}

@media (max-width: 850px) {
  .resume-top__root {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .resume-top__image {
    object-fit: cover;
    width: 50%;
    border-radius: 50%;
    margin-bottom: 50px;
  }
  .resume-top__image__wrapper {
    transition: all 0.2s ease-in-out;
    text-align: center;
  }
}
