.project-component__root {
    user-select: none;
    margin-top: 7vh;
    margin-bottom: 20px;
    margin-left: 10%;
    width: 100%;
  }
  .project-component__title {
    font-family: "Nunito";
    font-size: 2.2rem;
    font-weight: 700;
    color: #07a780;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .project-component__line {
    margin-top: 25px;
    margin-left: 5%;
    margin-right: 5%;
    width: 70%;
    height: 2px;
    background: #07a780;
    border: 2px solid #07a780;
    border-radius: 5px;
  }
  
  .project-component__projects {
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 50px;
    flex-wrap: wrap;
  }
  
  @media (max-width: 850px) {
    .project-component__root {
      user-select: none;
      margin-top: 7vh;
      margin-bottom: 20px;
      width: 80%;
      margin-left: 5%;
      margin-right: 0px;
    }
  }
  