.navbar-root {
  z-index: 9999;
  height: 25px;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 10px 20px 20px 20px;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
}

.active {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(41, 41, 41);
}

.not-active {
  background-color: rgba(41, 41, 41, 0.719);
  color: white;
}
