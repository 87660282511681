.landing-page-component-root {
  height: 40vh;
}


@media (max-width: 850px) {
  .landing-page-component-root {
    height: 30vh;
  }
}
