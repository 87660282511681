.see-more__root {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.see-more__text {
  user-select: none;
  font-weight: 300;
  font-size: 1.2rem;
  font-family: "Nunito", sans-serif;
}

.see-more__icon {
  cursor: pointer;
  color: #2c2c2c;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
  padding: 5px;
  animation: ripple 3s linear infinite;
}

.see-more__icon:hover {
  color: #1d1d1d;
  transform: scale(1.2);
}

@keyframes ripple {
  0% {
    background-color: rgba(0, 0, 0, 0.055);
    box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.055);
  }
  100% {
    background-color: rgba(77, 76, 76, 0);
    box-shadow: 0 0 0 4rem rgba(77, 76, 76, 0);
  }
}



@media (max-width: 850px) {
  .see-more__root {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .see-more__text {
    user-select: none;
    font-weight: 300;
    font-size: 1.0rem;
    font-family: "Nunito", sans-serif;
  }
  
}
