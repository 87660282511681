.welcome-text__root {
  z-index: 999;
  position: absolute;
  top: 20vh;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
  /* margin-top: 20vh; */
  margin-left: 35%;
}

.welcome-text__wrapper {
  display: flex;
  flex-direction: column;
}

.welcome-text__item {
  text-align: start;
  font-size: 3.5rem;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0.15rem;
  font-weight: 300 !important;
}

.welcome-text__item__strong {
  font-weight: 800 !important;
}

@media (max-width: 1300px) {
  .welcome-text__root {
    top: 20vh;
    margin-left: 25%;
  }
}

@media (max-width: 850px) {
  .welcome-text__root {
    top: 20vh;
    margin-left: 20%;
  }

  .welcome-text__item {
    text-align: start;
    font-size: 2.5rem;
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.15rem;
    font-weight: 300 !important;
  }
}
