.experience-component__root {
  user-select: none;
  margin-top: 7vh;
  margin-bottom: 20px;
  margin-left: 10%;
  width: 100%;
}

.experience-component__experiences {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 850px) {
  .experience-component__root {
    user-select: none;
    margin-top: 7vh;
    margin-bottom: 20px;
    width: 80%;
    margin-left: 5%;
    margin-right: 0px;
  }
}
