.project-item__root {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 460px;
  border-radius: 25px;
  padding: 5px 15px 5px 15px;
  margin-top: 20px;
  background: #07a780;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.project-item__root:hover {
  color: #fff;
  background: #454545;
  transform: scale(1.05);
}

.project-item__image__wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
  overflow: hidden;
}

.project-item__image {
  border-radius: 5px;
  object-fit: cover;
  width: 300px;
  height: 200px;
  background-size: cover;
}

.project-item__name {
  text-align: center;
  height: 30px;
  font-size: 1.2rem;
  font-weight: 800;
  font-family: "Nunito";
}

.project-item__description {
  font-size: 1.1rem;
  font-weight: 400;
  font-family: "Nunito";
  height: 250px;
}

.tooltip {
  position: relative;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.tooltip .tooltiptext {
  font-family: "Nunito";
  visibility: hidden;
  size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.514);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px 5px 20px;
  transition: all 0.2s ease-in-out;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -8%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 450px) {
  .project-item__root {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 460px;
    border-radius: 25px;
    margin: 5px 13px 5px 5px;
    background: #07a780;
    color: #fff;
    transition: all 0.2s ease-in-out;
  }

  .project-item__root:hover {
    color: #fff;
    background: #454545;
    transform: scale(1.05);
  }

  .project-item__image {
    border-radius: 5px;
    object-fit: cover;
    width: 250px;
    height: 200px;
    background-size: cover;
  }
}
